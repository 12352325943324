@import "../../components/theme/index";

.main {
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;

  .avatars {
    width: 163px;
    height: 163px;
  }

  &__text {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;

    &-bot {
      text-transform: none;
    }
  }
}

.info {
  margin: 0 auto 46px;
  max-width: 1120px;
  height: 100%;
  width: 100%;
  background: $grey;

  &__wrap {
    max-width: 685px;
    width: 100%;
    margin: 0 auto;
  }

  &__language {
    max-width: 640px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0 15px;

    @include respond-to(desktop) {
      padding: 45px 0 15px;
    }

    &-btn {
      width: 150px;
      height: 60px;
      padding: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0 15px;
    @include respond-to(desktop) {
      padding: 15px 0 0 0;
    }
  }

  &__item {
    box-sizing: border-box;
    height: 60px;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 15px;

    .input {
      font-size: 16px;
    }

    .input__label-text {
      font-size: 24px;
      color: $black;
    }

    .input__field {
      border: solid 1px $alto;
      font-size: 22px;
      @include respond-to(tablets) {
        font-size: 24px;
      }
    }

    .input__field--error {
      border: 1px solid $darkRed;
    }

    .input__error {
      font-size: 20px;
      padding: 4px 0;
    }
  }
}

.settings {

  .loader__wrapper {
    margin: 0 auto 40px;
  }

  &__modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
    max-height: 60vh;
    padding: 20px;
    overflow: auto;

    .modal {
      &__header {
        display: none;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 10px;
        height: 100%;

        .avatars {
          @include respond-to(tablets) {
            width: 50%;
            padding: 17px;
            margin: 0;
          }
          @include respond-to(desktop) {
            width: 33.3%;
          }

          &__item {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__title {
    color: $darkGrey;
    font-size: 38px;
    @include respond-to(tablets) {
      font-size: 48px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 0.75;
    @include respond-to(tablets) {
      margin: 10px 0 42px 50px;
    }
  }

  &__image {
    width: 33px;
    height: 34px;
    margin-right: 3px;
  }

  &__line {
    display: block;
    background-color: $grey;
    width: 100%;
    height: 60px;
    margin-bottom: 28px;
    position: relative;

    &:before {
      content: " ";
      background-color: $grey;
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 99%;
      top: 0;
    }

    &:after {
      content: " ";
      background-color: $grey;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 99%;
      top: 0;
    }
  }

  &__avatars {
    margin: 50px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    max-width: 1120px;
    @include respond-to(tablets) {
      flex-direction: row;
    }
  }

  &__save {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 60px 0;
    height: 60px;

    &-btn {
      max-width: 187px;
      width: 100%;
    }
  }

  &__log-out {
    padding: 0 15px;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto 70px;
    @include respond-to(desktop) {
      padding: 0;
    }
  }

  &__log-out-btn {
    width: 100%;
    height: 60px;
  }
}
