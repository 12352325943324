@import "../../theme/index";

.material {
  &.gritx-btn {
    min-height: 20px;
    line-height: 16px;
    width: 100%;
  }

  &--inactive.gritx-btn {
    height: 100%;
    font-size: 13px;
    @include respond-to(largedesktop) {
      font-size: 16px;
    }
    @include respond-to(mobile) {
      font-size: 13px!important;
    }
  }

  &--active.gritx-btn {
    position: absolute;
    height: 20%;
    font-size: 16px;
    font-weight: bold;
    bottom: 0;
    &:hover {
      color: $white;
    }

    @include respond-to(largedesktop) {
      font-size: 21px;
    }
    @include respond-to(mobile) {
      font-size: 16px!important;
    }
  }
}
