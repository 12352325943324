@import "../theme/index";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .gritx-btn {
    &:hover:not(:disabled) {
      transition: color 0.3s;
      color: $white;
    }

    &:disabled {
      background-color: $grey;
    }

    &.gritx-btn-primary {
      background-color: $accent;

      &:active {
        background: darken($accent, 6%);
      }

      &:disabled {
        background-color: $grey;
        color: $alto;
      }
    }

    &.gritx-btn-secondary {
      background-color: $alternate;
      color: $white;

      &:hover:not(:disabled) {
        color: $black;
      }

      &:active {
        background-color: darken($alternate, 6%);
      }

      &:disabled {
        background-color: $grey;
      }
    }

    &.gritx-btn-outline {
      background-color: $white;
      color: $black;

      &:hover:not(:disabled) {
        background-color: $white;
        color: $text_secondary;
      }

      &:disabled {
        background-color: $grey;
      }
    }
  }

  .bot-chat {
    &__toolbar {
      background-color: $white;
    }
  }

  .chat-message__message-right .chat-message__message-value {
    background: $white;
    color: $black;
    border: 1px solid $accent2;
    &:before {
      border-left-color: $white;
    }
  }

  .chat-message__message-left .chat-message__message-value {
    background: $alternate;
    color: $white;

    &:before {
      border-right-color: $alternate;
    }
  }

  .d-checkbox {
    [type="checkbox"] {
      display: none;

      +label {
        &:after {
          background-color: $accent;
        }
      }
    }
  }
}

.container {
  max-width: 1920px;
  margin: 0 auto;

  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main {
  margin-top: 100px;
  flex: 1 1 auto;
  overflow-x: hidden;
  @include respond-to(mobile) {
    margin-top: 50px;
    padding-top: 25px;
  }
  @include respond-to(tablets) {
    padding-top: 25px;
  }
  @include respond-to(desktop) {
    padding-top: 50px;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
