@import "../theme/index";

.main-area {
  margin: 15px;
  @include respond-to(tablets) {
    margin: 0 25px;
  }
  @include respond-to(desktop) {
    margin-left: 50px;
    margin-right: 0;
  }

  &__wrapper {
    display: flex;
    margin-bottom: 60px;
    justify-content: space-between;
  }

  &__about {
    margin-bottom: 60px;
    flex: 1 1 auto;
    @include respond-to(desktop) {
      margin-right: 30px;
      margin-bottom: 0;
    }
    @include respond-to(largedesktop) {
      margin-right: 60px;
    }

    &-content {

      @include respond-to(largedesktop) {
        display: flex;
      }
    }

    &-video {
      width: 100%;
      margin-bottom: 30px;
      @include respond-to(desktop) {
        min-width: 55%;
        margin-bottom: 60px;
      }
      @include respond-to(largedesktop) {
        padding: 0;
        margin-bottom: 0;
        margin-right: 60px;
        flex: 1 1 auto;
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include respond-to(largedesktop) {
        max-width: 600px;
      }
    }

    &-text {
      font-size: 18px;
      margin: 0 0 15px;
      @include respond-to(largedesktop) {
        font-size: 1.2vw;
      }
      @include respond-to(fullHD) {
        font-size: 24px;
      }
      &:first-child{
        margin-top: -0.2em;
      }
    }

    &-button {
      min-height: 70px;
      font-size: 36px;
      line-height: 70px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-weight: bold;
      border: none;
      background-color: $accent;
      transition: color 0.3s;
      cursor: pointer;

      &:hover {
        transition: color 0.3s;
        color: $white;
      }
    }
  }

  &__apps {
    display: none;
    @include respond-to(desktop) {
      display: block;
    }

    .apps {
      width: 260px;
      //background-color: $grey;
      margin: -50px 0 0;
      min-height: 550px;
      height: calc(100% + 50px);
      padding-top: 60px;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-text {
          font-size: 36px;
          font-weight: bold;
          color: $alternate;
          text-transform: uppercase;
          padding-bottom: 20px;
        }
      }

      &__link {
        font-size: 24px;
        display: block;
        color: $black;
        text-align: center;
        font-weight: bold;
        width: 160px;

        &:visited {
          color: $black;
        }
      }

      &__store {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__qr {
        width: 160px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &-logo {
          width: 57px;
          height: 68px;
        }

        &-code {
          width: 69px;
          height: 69px;
          transition: all 0.3s;

          &:hover {
            transition: all 0.3s;
            cursor: pointer;
            position: absolute;
            width: 100%;
            right: 0;
          }

          &-image {
            width: 100%;
            height: auto;
          }
        }

        &-image {
          width: 70px;
          height: 70px;
        }

      }

      &__button {
        width: 160px;

        &:hover {
          cursor: pointer;
        }

        &-image {
          width: 100%;
        }

        &--link {
          margin: 50px 0;
        }
      }
    }
  }

  &__header {
    margin: 0 0 30px;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: bold;
    color: $darkGrey;
  }

  &__sections {
    @include respond-to(largedesktop) {
      display: flex;
      margin-right: 50px;
    }

    .section {
      margin-bottom: 60px;
      flex: 1 1 auto;
      @include respond-to(tablets) {
        display: flex;
        max-width: 880px;
        &:first-child {
          margin-right: 60px;
        }
      }
      @include respond-to(largedesktop) {
        flex-basis: 600px;
        width: 50%;
      }

      &__image {
        height: 100%;
        display: block;
        margin: 0 auto;

        &-wrapper {
          height: 300px;
          @include respond-to(tablets) {
            height: 200px;
            min-width: 200px;
            flex: 1 1 auto;
            margin-right: 30px;
          }
          @include respond-to(largedesktop) {
            min-width: 300px;
            height: 300px;
          }

          &--green {
            background-color: $green;
          }
        }

      }

      &__about {
        color: $darkGrey;
      }

      &__title {
        font-size: 36px;
        font-weight: bold;
        margin: -0.2em 0 12px;
      }

      &__description {
        font-size: 18px;
        line-height: normal;
        font-weight: normal;
        margin-top: -0.2em;
        margin-bottom: 15px;
        @include respond-to(largedesktop) {
          font-size: 1.2vw;
        }
        @include respond-to(fullHD) {
          font-size: 24px;
        }
      }
    }
  }
}

