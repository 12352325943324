@import "../../components/theme/index";

.materials {
  position: relative;

  &__title {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: $darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
      font-size: 48px;
    }
    @include respond-to(mobile) {
      font-size: 24px;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    padding: 7px;
    @include respond-to(mobile) {
      width: 40px;
      height: 40px;
    }
  }

  &__cards {
    position: relative;
    min-height: 300px;
  }

  &__share-line {
    position: relative;
  }

  &__done {
    padding: 10px;

    &-text {
      margin-top: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      @include respond-to(tablets){
        font-size: 24px;
      }
      @include respond-to(largedesktop){
        font-size: 36px;
      }

    }
  }

  &__image {
    width: 100%;

    &-wrapper {
      max-width: 450px;
      margin: 0 auto;
    }
  }
}