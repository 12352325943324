@use "sass:color";
@import "../theme/index";

.bot-chat {

  .article {
    margin-top: 0;

    &__heading {
      padding: 0 40px;
      font-size: 35px;
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #C1E6D9;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C1E6D9;
  }
}