@import "../../../theme/index";

.xpedition-item {
  //border: 1px solid black;
  //box-shadow: 2px 2px 0px 0px;
  margin-bottom: 10px;
  padding: 5px;

  &__header {
    color: $alternate;
    height: 60px;
    background-color: $white;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    color: $alternate;
    font-weight: bolder;
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 30px;
    text-transform: capitalize;
  }

  &__body {
    border-bottom: 1px solid $darkGrey;
    padding-bottom: 20px;

    @include respond-to(tablets) {
      display: flex;
    }
  }

  &__image {
    width: 100%;

    &-wrapper {
      max-width: 20%;
    }
  }

  &__description {
    padding: 20px;
    flex: 1 1 auto;
    @include respond-to(tablets) {
      padding: 0 20px;
    }
  }

  &__button {
    & {
      width: 100%;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding-bottom: 20px;
    }

    &:hover {
      transition: color 0.3s;
      color: $silver;
    }

    &-percent {
      font-size: 48px;
      font-weight: bold;
      display: block;
      margin-top: 50px;
    }

    &-label {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 20px;
    }

    &-wrapper {
      min-width: 25%;
    }

    &--remove {
      margin-top: 10px;
      width: 100%;
    }
  }
}