@import "../../theme/index";

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 33px 0 44px;
  background-color: $accent;
  padding: 10px;

  @include respond-to(tablets) {
    padding: 10px 50px 10px 90px;
  }

  &__buttons-group {
    display: flex;
    margin: 5px 0;
    overflow-x: auto;

    @include respond-to(tablets) {
      flex: 1;
      overflow-x: inherit;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    flex: 1.3;
    height: 61px;
    border: solid 1px $alto;
    background-color: $white;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 300px;
    margin: 5px 0;

    &-field {
      min-height: auto;
      width: 100%;
      .input {
        &__label-text {
          margin: 0;
        }

        &__field {
          border: none;
          padding: 10px 36px;
        }
      }
    }
  }
}
