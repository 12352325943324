@import "../theme/index";

.contact-line{
  &__border{
    height: 50px;
    background-color: $alternate;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
    @include respond-to(tablets) {
      flex-direction: row;
    }

    &__cry-picture {
      width: 100px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      @include respond-to(tablets) {
        width: 150px;
        margin-top: 0;
        margin-bottom: 0;
      }
      @include respond-to(desktop) {
        width: 300px;
      }
      @include respond-to(largedesktop) {
        width: 320px;
      }
      @include respond-to(widedesktop) {
        width: 350px;
      }
    }

    &__text {
      display: flex;
      flex-direction: row;
    }

    &__numbers {
      color: $black;
      font-size: 18px;
      line-height: normal;
      font-weight: normal;
      align-self: center;
      @include respond-to(mobile) {
        font-size: 14px;
      }
      @include respond-to(tablets) {
        font-size: 16px;
      }
      @include respond-to(desktop) {
        font-size: 18px;
      }
      @include respond-to(largedesktop) {
        font-size: 20px;
      }
      @include respond-to(widedesktop) {
        font-size: 23px;
      }
      @include respond-to(bigdesktop) {
        font-size: 30px;
      }

      &__text-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        font-size: 18px;
        @include respond-to(mobile) {
          font-size: 16px;
        }
        @include respond-to(tablets) {
          font-size: 16px;
        }
        @include respond-to(desktop) {
          font-size: 18px;
          flex-direction: row;
        }
        @include respond-to(tablets) {
          flex-direction: row;
        }
      }

      &__life-line {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        @include respond-to(desktop) {
          flex-direction: row;
        }
        @include respond-to(desktop) {
          flex-direction: row;
          margin-top: 41px;
        }
      }

      &__title {
        margin-right: 6px;
      }

      &__number {
        font-weight: bold;
        color: #6547E6;
        margin-left: 6px;
        margin-right: 6px;
        @include respond-to(desktop) {
          margin-top: 0;
          margin-left: 6px;
        }
      }

      &__bold {
        font-weight: bold;
        margin-left: 6px;
        margin-right: 6px;
      }
    }

    &__pictures {
      margin-top: 10px;
      align-self: center;
      @include respond-to(tablets) {
        margin-top: 71px;
        align-self: normal;
      }
      @include respond-to(largedesktop) {
        margin-top: 30px;
      }
      @include respond-to(widedesktop) {
        margin-top: 10px;
      }

      &__run {
        width: 51px;
        height: 78px;
        margin-top: 46px;
        @include respond-to(desktop) {
          width: 65px;
          height: 111px;
          margin-top: 0;
          margin-bottom: 5px;
        }
        @include respond-to(largedesktop) {
          width: 89px;
          height: 137px;
          margin-bottom: 0;
        }
        @include respond-to(widedesktop) {
          width: 106px;
          height: 163px;
        }
      }

      &__tree {
        max-width: 80%;
        width: 250px;
        height: 124px;
        margin-bottom: -1px;
        @include respond-to(desktop) {
          width: 316px;
          height: 185px;
        }
        @include respond-to(largedesktop) {
          width: 437px;
          height: 217px;
        }
        @include respond-to(widedesktop) {
          width: 520px;
          height: 258px;
        }
      }
    }

    &__disclaimer {
      margin: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-to(desktop) {
        margin-top: 41px;
      }
    }

    &__disclaimer-text {
      line-height: normal;
      font-weight: normal;
      text-align: center;
      font-size: 18px;
      @include respond-to(mobile) {
        font-size: 14px;
      }
      @include respond-to(tablets) {
        font-size: 16px;
      }
      @include respond-to(desktop) {
        font-size: 18px;
      }
      @include respond-to(largedesktop) {
        font-size: 20px;
      }
      @include respond-to(widedesktop) {
        font-size: 23px;
      }
      @include respond-to(bigdesktop) {
        font-size: 30px;
      }
    }

  }

  &__title {
    background-color: $black;

    &__text {
      color: $white;
      font-size: 25px;
      padding: 24px 3% 24px;
      @include respond-to(desktop) {
        font-size: 38px;
        padding: 26px 3%;
      }
      @include respond-to(largedesktop) {
        font-size: 48px;
      }
    }
  }
}
