@import "../../components/theme/index";

.resource {
  &__filters {
    position: relative;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: $darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
    }
    @include respond-to(mobile) {
      font-size: 24px;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    padding: 7px;
    @include respond-to(mobile) {
      width: 40px;
      height: 40px;
    }
  }

  &__image {
    max-height: 200px;
    margin-bottom: 60px;

    @include respond-to(tablets) {
      max-height: 300px;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
  }
}
.resource-filters {
  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
  }

  &__button {
    width: 245px;
    margin: 5px 15px 5px auto;
  }
}
